import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authenticate } from "../utils";

const handleUserAction = async ({ payload, clearForm, setErrorMessage }, authType) => {
    try {
        const user = await authenticate(authType, payload);

        if (user.token || user.email) {
            clearForm();
            return user;
        } else if (user.error) {
            setErrorMessage(user.error);
        } else if (user) {
            setErrorMessage(user.toString());
        }
    } catch (error) {
        console.error("Error in user action:", error);
        return error;
    }
};

export const login = createAsyncThunk("user/login", async (args) => handleUserAction(args, "signin"));
export const signup = createAsyncThunk("user/signup", async (args) => handleUserAction(args, "signup"));


const userSlice = createSlice({
    name: "user",
    initialState: {
        data: JSON.parse(localStorage.getItem("user")) || {},
    },
    reducers: {
        logout(state) {
            state.data = {};
        },
        change_ui(state, action) {
            localStorage.setItem("user", JSON.stringify(action.payload))
            state.data = action.payload
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.data = action.payload || {};
        },
        [signup.fulfilled]: (state, action) => {
            state.data = action.payload || {};
        },
    },
});

export const { logout, change_ui } = userSlice.actions;

export default userSlice.reducer;

import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Box, CardMedia, CircularProgress, Grid, Paper, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {LoadingButton} from "@mui/lab";
import {getFileUrl} from "../../../utils";
import {BrokenImageOutlined, MenuBookTwoTone, Visibility} from "@mui/icons-material";
import ReaderDialog from "./ReaderDialog";
import {NavLink} from "react-router-dom";

const ElementGrid = ({document, downloadDocument, getCover, getDocument}) => {
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingGetUrl, setLoadingGetUrl] = useState(false)
    const [loadingCover, setLoadingCover] = useState(false)
    const [error, setError] = useState("")
    const [cover, setCover] = useState(null)
    const [showReader, setShowReader] = useState(false)

    const fileSize = document.file_size/1024/1024 || 0

    const downloadFile = (id) => {
        setLoadingDownload(true)
        downloadDocument(id, () => setLoadingDownload(false), res => {
            if (res?.includes("Request failed with status code 500")) {
                setError("Something went wrong...")
            } else {
                setError(res)
            }
            setLoadingDownload(false)
        })
    }

    const getUrl = async (id) => {
        setLoadingGetUrl(true)
        const res = await getFileUrl(id)
        setLoadingGetUrl(false)
        // but for what??
    }

    useEffect(() => {
        setLoadingCover(true)
        getCover(document.id, res => {
            setCover(res)
            setLoadingCover(false)
        }, () => setLoadingCover(false))
    }, [document.id])

    return <Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper elevation={10} className='paper-grid-wrapper'>
            {error &&
                <Alert severity="error" className="error" onClose={() => setError("")}>
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            }
            <Box className="box-description">
                <Box className="cover-wrapper">
                    {loadingCover ?
                        <CircularProgress className="loading-cover"/>
                        :
                        cover ?
                            <CardMedia
                                className="cover"
                                image={cover}
                                title={document.title}
                            />
                            :
                            <BrokenImageOutlined className="no-cover"/>
                    }
                </Box>
                <Typography variant="h5" component="div">
                    {document.author && document.title ? `${document.author} - ${document.title}` : document.file_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    ID: <b>{document.id}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Extension: <b>{document.file_extension}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Size: <b>{fileSize.toFixed(2)} MB</b>
                </Typography>
            </Box>
            <Box className="box-actions">
                <Grid container spacing={1} className="buttons-wrapper">
                    <Grid item xs={3} className="action-button">
                        <LoadingButton size="small" loading={loadingDownload} fullWidth variant="contained" color="primary" onClick={() => downloadFile(document.id)}>
                            <DownloadIcon />
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={3} className="action-button">
                        <LoadingButton size="small" loading={loadingGetUrl} fullWidth variant="contained" color="primary" onClick={() => getUrl(document.id)}>
                            URL
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={3} className="action-button">
                        <LoadingButton size="small" fullWidth variant="contained" color="primary" onClick={() => setShowReader(true)}>
                            <Visibility/>
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={3} className="action-button">
                        <LoadingButton size="small" fullWidth variant="contained" color="primary" component={NavLink} to={`/read/${document.id}`}>
                            <MenuBookTwoTone/>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
        {showReader && <ReaderDialog
            id={document.id}
            getDocument={getDocument}
            onClose={() => setShowReader(false)}
        />}
    </Grid>
}

export default ElementGrid;
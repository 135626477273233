
export const client = async (endpoint, { body, ...customConfig } = {}) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  if (customConfig.token || (!customConfig.token && user?.token)) {
    config.headers.authorization = `Bearer ${customConfig.token || user.token}`;
  }

  try {
    const res = await fetch(endpoint, config);
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error in client:", error);
    return error;
  }
};

export const authenticate = async (type, data) => {
  try {
    const res = await client(`/api/auth/${type}`, { body: data });

    if (res.data) {
      const { data: user } = await client(`/api/auth/user`, { token: res.data });

      localStorage.setItem("user", JSON.stringify({ ...user, token: res.data }));

      return { ...user, token: res.data };
    } else {
      return res;
    }
  } catch (err) {
    console.error("Error in authenticate:", err);
    return err;
  }
};

export const updateUserLocalSt = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const updatedUser = { ...user, ...data };
  localStorage.setItem("user", JSON.stringify(updatedUser));
};

const getFileUrl = async (id, token_callbck) => {
  const response = await client(`/api/document/${id}/token`, {})
  return `${window.location.origin}/api/document/token/${response.token}/document.epub`
};


export { getFileUrl }

import React, {useEffect, useState} from "react";
import "./Reader.scss"
import {getDocument} from "../../pages/SearchPage/SearchPageApi";
import PdfReader from "./Reader/PdfReader";
import EpubReader from "./Reader/EpubReader";
import {Box} from "@mui/material";

const Reader = ({id, height}) => {
    const [file, setFile] = useState(null)
    const [extension, setExtension] = useState(null)
    const [loadingFile, setLoadingFile] = useState(true)

    const readerSwitch = () => {
        switch (extension) {
            case 'pdf':
                return <PdfReader file={file} height={height} loading={loadingFile}/>
            case 'epub':
                return <EpubReader file={file} height={height} loading={loadingFile}/>
            case 'mobi':
                return <EpubReader file={file} height={height} loading={loadingFile}/>
            default:
                return <PdfReader file={file} height={height} loading={loadingFile}/>
        }
    }

    useEffect(() => {
        setLoadingFile(true)
        getDocument(id, extension, res => {
            setFile(res.file)
            setExtension(res.extension)
            setLoadingFile(false)
        }, () => setLoadingFile(false))
    }, [])

    return <Box id="reader-box">
        {readerSwitch()}
    </Box>
}

export default Reader;
import apiHandler from "../Defaults/ApiHandler";
import axios from "axios";

export const changeUi = (callback, errorCallback) => {
    const config = {
        url: '/api/auth/user/change_ui'
    }
    apiHandler("Getting filters", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, errorCallback)
}
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Navbar.scss";
import {Button, FormControlLabel, FormGroup, Grid, Switch, Typography} from "@mui/material";
import AppBar from '@mui/material/AppBar';
import { logout, change_ui } from "../../reducers/user";
import { NavLink } from "react-router-dom";
import {changeUi} from "./NavbarApi";

const Navbar = () => {
  const dispatch = useDispatch();
  const { first_name, simple_ui } = useSelector(state => state.user.data);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("user");
  };

  return (
    <AppBar position="sticky" id="nav-bar">
      <Grid className="button-wrapper">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className='welcome-text'>
              Happy hunting, {first_name}
          </Typography>
          <Button variant="contained" color="primary" className="piano-key" component={NavLink} to="/">
              Home
          </Button>
          <Button variant="contained" color="primary" className="piano-key" component={NavLink} to="/search">
              Search
          </Button>
          <Button variant="contained" color="primary" className="piano-key" onClick={handleLogout} to="/search">
              Logout
          </Button>
      </Grid>
      <div className="padding-box"/>
      <FormGroup className="simple-ui-switch-wrapper">
          <FormControlLabel
              checked={simple_ui}
              control={<Switch />}
              onClick={() => changeUi((res) => {
                  let user = JSON.parse(localStorage.getItem("user"))
                  user = {...user, simple_ui: res.simple_ui}
                  dispatch(change_ui(user));
              })}
              label="Simple UI"
          />
      </FormGroup>
    </AppBar>
  );
};

export default Navbar;

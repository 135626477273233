import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

const darkTheme = createTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: '#db3131',
                '&$error': {
                    color: '#db3131'
                },
                '&$warning': {
                    color: '#db3131'
                },
            }
        },
    },
    colorMenu: '#fff',
    palette: {
        mode: 'dark',
        primary: {
            main: '#bd9a00',
            contrastText: '#fff',
            gray: {
                main: '#B1B6C0',
                contrastText: '#fff',
            },
            action: {
                main: '#daa081',
                contrastText: '#000',
            },
            secondary: {
                main: '#db3131',
                contrastText: '#fff',
            },
            error: {
                main: red.A400,
                contrastText: '#fff',
            },
            background: {
                default: "#181818",
            },
            text: {
                primary: '#fff',
                secondary: '#bdbdbd',
            },
        },
    }
});

export default darkTheme;

import React, { useState, useEffect, useRef } from "react";
import {Container,} from "@mui/material";
import DocumentGrid from "./DocumentGrid/DocumentGrid";
import "./SearchPage.scss";
import {downloadDocument, getCover, getDocument, getFilters, searchDocuments} from "./SearchPageApi";
import SearchBar from "./DocumentGrid/SearchBar";

const fileTypes = [
  {value: " ", label: "All Types"},
  {value: "epub", label: ".epub"},
  {value: "mobi", label: ".mobi"},
  {value: "pdf", label: ".pdf"},
  {value: "docx", label: ".docx"},
]

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState("")
  const [loadingInput, setLoadingInput] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const [noResultText, setNoResultText] = useState(false)
  const [total, setTotal] = useState(0)
  const [authors, setAuthors] = useState([])
  const [subjects, setSubjects] = useState([])
  const [prevFilters, setPrevFilters] = useState({
    extension: " ",
    authors: [],
    subjects: []
  });
  const [filters, setFilters] = useState({
    extension: " ",
    authors: [],
    subjects: []
  });

  const searchDocumentsDebounced = useRef(null);
  const topRef = useRef(null);

  const updatePage = () => {
    setPage(page + 1)
  }

  const reload = (loadingFn, pageChange = false) => {
    loadingFn(true)
    setNoResultText(false)
    setError("")
    searchDocuments(
        searchTerm,
        filters.extension.trim(),
        filters.authors.map(el => el.value),
        filters.subjects.map(el => el.value),
        page,
        res => {
          const docs = res.results
          const total_count = res.total

          setTotal(total_count)

          if (pageChange) {
            setSearchResults(searchResults.concat(docs))
          } else {
            setSearchResults(docs)
            if (docs.length === 0) {
              setNoResultText(true)
            }
          }
          loadingFn(false)
        }, res => {
          setError(res)
          loadingFn(false)
        })
  }

  const searchOnSelectClose = () => {
    if (filters.authors.length > 0 || filters.subjects.length > 0 || searchTerm.length >= 3) {
      topRef.current.scrollIntoView({behavior: 'smooth'})
      reload(setLoadingInput)
    }
  }

  const setNewFilters = newFilters => {
    setPrevFilters(filters)
    setFilters(newFilters)
  }

  useEffect(() => {
    if (searchTerm.length >= 3 || filters.authors.length > 0 || filters.subjects.length > 0) {
      if (searchDocumentsDebounced.current) {
        clearTimeout(searchDocumentsDebounced.current);
      }

      searchDocumentsDebounced.current = setTimeout(() => {
        topRef.current.scrollIntoView({ behavior: 'smooth' })
        reload(setLoadingInput)
      }, 2000);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (filters.authors.length > 0 || filters.subjects.length > 0 || searchTerm.length >= 3) {
      topRef.current.scrollIntoView({ behavior: 'smooth' })
      reload(setLoadingInput)
    }
  }, [filters.extension])

  useEffect(() => {
    if (((filters.authors.length > 0 || filters.subjects.length > 0) && prevFilters.authors.length > filters.authors.length) ||
        ((filters.subjects.length > 0 || filters.authors.length > 0) && prevFilters.subjects.length > filters.subjects.length))
    {
      topRef.current.scrollIntoView({ behavior: 'smooth' })
      reload(setLoadingInput)
    }
  }, [filters.authors, filters.subjects])

  useEffect(() => {
    if (page > 1) {
      reload(setLoadingPage, true)
    }
  }, [page])

  useEffect(() => {
    getFilters(res => {
      setAuthors(res.authors)
      setSubjects(res.subjects)
    })
  }, [])

  return (
    <Container id="search-page">
      <div ref={topRef}/>
      <SearchBar
          loadingInput={loadingInput}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          total={total}
          filters={filters}
          setNewFilters={setNewFilters}
          fileTypes={fileTypes}
          searchResults={searchResults}
          noResultText={noResultText}
          error={error}
          setError={setError}
          authors={authors}
          subjects={subjects}
          searchOnSelectClose={searchOnSelectClose}
      />
      <DocumentGrid
          documents={searchResults}
          downloadDocument={downloadDocument}
          getCover={getCover}
          getDocument={getDocument}
          updatePage={updatePage}
          loadingPage={loadingPage}
          total={total}
      />
    </Container>
  );
};

export default SearchPage;

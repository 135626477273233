import apiHandler from "../../components/Defaults/ApiHandler";
import axios from "axios";
import { saveAs } from 'file-saver';

export const searchDocuments = (searchTerm, extension, authors, subjects, page, callback, errorCallback) => {
    const config = {
        url: '/api/document/search',
        method: "POST",
        data: { query: searchTerm, ext: extension, page: page, authors: authors, subjects: subjects },
    }
    apiHandler("Searching...", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, errorCallback)
}

export const getFilters = (callback, errorCallback) => {
    const config = {
        url: '/api/document/get_filters'
    }
    apiHandler("Getting filters", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, errorCallback)
}

export const downloadDocument = (id, callback, errorCallback) => {
    const config = {
        url: `/api/document/${id}/download`
    }
    apiHandler("Download document", config, async config => {
        const response = await axios.request(config);
        if (response.data) {
            let binaryString = window.atob(response.data?.data);
            let arrayBuffer = new ArrayBuffer(binaryString.length);
            let byteArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryString.length; i++) {
                byteArray[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([byteArray], { type: 'application/octet-stream' });
            saveAs(blob, response?.data?.name);
        }
        callback && callback()
    }, errorCallback)
}

export const getCover = (id, callback, errorCallback) => {
    const config = {
        url: `/api/cover/${id}`,
        responseType: 'arraybuffer'
    }
    apiHandler("Getting cover", config, async config => {
        const response = await axios.request(config);
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        const objectURL = URL.createObjectURL(blob);
        callback && callback(objectURL)
    }, errorCallback)
}

export const getDocument = (id, callback, errorCallback) => {
    const config = {
        url: `/api/document/${id}/download`
    }
    apiHandler("Download document", config, async config => {
        const response = await axios.request(config);
        if (response.data) {
            let binaryString = window.atob(response.data?.data);
            let arrayBuffer = new ArrayBuffer(binaryString.length);
            let byteArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryString.length; i++) {
                byteArray[i] = binaryString.charCodeAt(i);
            }

            let result = null
            if (response.extension === 'pdf') {
                result = byteArray
            } else if (response.extension === 'epub') {
                const blob = new Blob([byteArray], {type: 'application/epub+zip'});
                blob.name = response?.data?.name
                result = blob
            } else if (response.extension === 'mobi') {
                const blob = new Blob([byteArray], {type: 'application/mobi'});
                blob.name = response?.data?.name
                result = blob
            }

            callback && callback({file: result, extension: response.extension})
        }
    }, errorCallback)
}
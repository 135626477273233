import React, {useEffect, useRef, useState} from "react";
import {Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import * as pdfjs from 'pdfjs-dist/build/pdf';
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfReader = ({file, height, loading}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [disabledButtons, setDisabledButtons] = useState(false)
    const [zoom, setZoom] = useState(1)
    const [settingsRight, setSettingsRight] = useState(["invert"])

    const canvasRef = useRef(null);

    const renderPDF = async (newPage) => {
        setDisabledButtons(true)
        const loadingTask = pdfjs.getDocument({ data: file });
        const pdf = await loadingTask.promise;

        if (!numPages) {
            setNumPages(pdf.numPages)
        }

        let nextPage = 1
        if (newPage >= 1) nextPage = newPage
        if (newPage > pdf.numPages) nextPage = pdf.numPages

        const page = await pdf.getPage(nextPage);

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = page.view[2]
        canvas.height = page.view[3];

        const renderContext = {
            canvasContext: context,
            viewport: page.getViewport({ scale: 1.0 }),
        };

        await page.render(renderContext);
        setPageNumber(nextPage)

        setDisabledButtons(false)
    };

    const changeSettingsRight = (event, newFormats) => {
        setSettingsRight(newFormats);
    };

    useEffect(() => {
        if (file) {
            renderPDF(pageNumber);
        }
    }, [file, zoom]);

    return <Box height={height} id="pdf-reader">
        {loading ?
            <Box className="loading-screen">
                <CircularProgress/>
            </Box>
            :
            file ?
                <canvas
                    ref={canvasRef}
                    style={{height: height * zoom}}
                    className={settingsRight.includes("invert") ? "invert-color" : ""}
                />
                :
                <Box className="loading-screen">
                    <span>Error loading file</span>
                </Box>
        }

        <Grid className="footer">
            <Grid>
                <Button
                    disabled={disabledButtons || loading || !file}
                    onClick={() => renderPDF(pageNumber - 1)}
                >
                    Previous Page
                </Button>
                <Button
                    variant="outlined"
                    disabled={disabledButtons || loading || !file}
                    onClick={() => {
                        if (zoom < 1.8) setZoom(zoom + 0.1)
                    }}
                >
                    Zoom In
                </Button>
                <Button
                    variant="outlined"
                    disabled={disabledButtons || loading || !file}
                    onClick={() => {
                        if (zoom > 0.5) setZoom(zoom - 0.1)
                    }}
                >
                    Zoom Out
                </Button>
            </Grid>
            <Typography variant="h6">Page {pageNumber} of {numPages}</Typography>
            <Grid>

                <ToggleButtonGroup
                    value={settingsRight}
                    onChange={changeSettingsRight}
                    aria-label="text formatting"
                >
                    <ToggleButton size="small" value="invert" color="success" disabled={disabledButtons || loading || !file}>Invert Color</ToggleButton>
                </ToggleButtonGroup>
                <Button
                    disabled={disabledButtons || loading || !file}
                    onClick={() => renderPDF(pageNumber + 1)}
                >
                    Next Page
                </Button>
            </Grid>
        </Grid>
    </Box>
}

export default PdfReader;
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {login} from "../../reducers/user";
import {Box, TextField, Button, Typography, Container, Alert, AlertTitle, Paper, Grid} from "@mui/material";
import "./Auth.scss"
import {LoadingButton} from "@mui/lab";
import {useFormik} from "formik";

const Login = ({setAuth}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        onSubmit: (values) => {
            setLoading(true)
            const clearForm = () => {
                formik.setValues({
                    email: "",
                    password: ""
                })
                setLoading(false)
            };

            const setErrorMessage = (error) => {
                if (error.includes("SyntaxError")) {
                    setError("Something went wrong...")
                } else {
                    setError(error)
                }
                setLoading(false)
            }

            dispatch(login({payload: values, clearForm, setErrorMessage}));
        }
    });

    return (
        <Container component="main" maxWidth="xs" id="login-page">
            <Paper elevation={10} className='paper-wrapper'>
                <Box>
                    <Grid align='center' sx={{paddingBottom: "20px"}}>
                        <Typography variant="h5">Welcome to yBook</Typography>
                    </Grid>
                    {error && error.length > 0 &&
                        <Grid sx={{paddingBottom: "20px"}}>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {error}
                            </Alert>
                        </Grid>
                    }
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item className="auth-text-field">
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={formik.values?.email}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item className="auth-text-field">
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={formik.values?.password}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item className="auth-button">
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                >
                                    Login
                                </LoadingButton>
                            </Grid>
                            <Grid item className="auth-button redirect-button">
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="info"
                                    onClick={() => setAuth("SIGNUP")}
                                >
                                    Signup instead
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
};

export default Login;

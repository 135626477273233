import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route, 
  Navigate
} from "react-router-dom";
import SearchPage from "./pages/SearchPage/SearchPage";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import ReadPage from "./pages/ReadPage/ReadPage";

const AppRouter = () => (
  <Router>
    <Navbar />
      <Routes>
        <Route path="/search" element={<SearchPage />} />
        <Route path="/read/:id" element={<ReadPage />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
  </Router>
);

export default AppRouter;

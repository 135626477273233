import React from 'react';
import { useSelector } from "react-redux";
import {Container, ThemeProvider, CssBaseline  } from "@mui/material";
import Auth from "./components/Auth/Auth"
import Router from "./Router";
import './App.scss';
import darkTheme from "./styles/themes/darkTheme";

const App = () => {
    const { token } = useSelector((state) => state.user.data);
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Container id="root" className='root-container'>
                {token ? <Router /> : <Auth />}
            </Container>
        </ThemeProvider>
    );
}

export default App;

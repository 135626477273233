import React from "react";
import {useParams} from "react-router-dom";
import Reader from "../../components/Defaults/Reader";

const ReadPage = () => {
    const {id} = useParams()

    return id && <Reader id={id} height={window.innerHeight - 100}/>
}

export default ReadPage;
import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { signup } from "../../reducers/user";
import {Button, TextField, Typography, Box, Container, Grid, Alert, AlertTitle, Paper} from '@mui/material';
import {useFormik} from "formik";
import * as yup from "yup";
import {LoadingButton} from "@mui/lab";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

const Signup = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [emptyFields, setEmptyFields] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirmPassword: false,
  })

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    first_name: yup
        .string()
        .required('The first name is required')
        .min(2, 'The username must be between 2 and 20 characters.')
        .max(20, 'The username must be between 2 and 20 characters.'),
    last_name: yup
        .string()
        .required('The last name is required')
        .min(2, 'The username must be between 2 and 20 characters.')
        .max(20, 'The username must be between 2 and 20 characters.'),
    email: yup
        .string()
        .required('Email is required')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Invalid email'),
    password: yup
        .string()
        .required('The password is required')
        .min(5, 'The password must be between 5 and 40 characters.')
        .max(40, 'The password must be between 5 and 40 characters.'),
    confirmPassword: yup
        .string()
        .required('The password confirmation is required')
        .oneOf([yup.ref('password'), null], 'Password must match!')
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setSuccessful(false);
      const clearForm = () => {
        formik.setValues({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirmPassword: ""
        })
        setLoading(false);
        setSuccessful(true);
        setTimeout(() => {
          setAuth("LOGIN")
        }, 3000)
      };

      const setErrorMessage = (error) => {
        if (error.includes("SyntaxError")) {
          setError("Something went wrong...")
        } else {
          setError(error)
        }
        setLoading(false);
        setSuccessful(false);
      }

      setError("")
      dispatch(signup({ payload: values, clearForm, setErrorMessage }));
    },
    validateOnChange: false,
    validateOnMount: false
  });

  const onFocusError = (key) => {
    setEmptyFields({
      ...emptyFields,
      [key]: false
    })
  }

  useEffect(() => {
    if (!formik.isSubmitting) {
      setEmptyFields({
        first_name: !!formik.errors.first_name,
        last_name: !!formik.errors.last_name,
        email: !!formik.errors.email,
        password: !!formik.errors.password,
        confirmPassword: !!formik.errors.confirmPassword,
      })
    }
  }, [formik.isSubmitting]);

  return (
      <Container component="main" maxWidth="xs" id="register-page">
        <Paper elevation={10} className='paper-wrapper'>
          <Box>
            <Grid align='center' sx={{paddingBottom: "20px"}}>
              <Typography variant="h5">{successful ? 'Success!' : 'Create your account'}</Typography>
            </Grid>
            {error && error.length > 0 &&
                <Grid sx={{paddingBottom: "20px"}}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                  </Alert>
                </Grid>
            }
            <form onSubmit={formik.handleSubmit}>
              <Grid container direction="column" spacing={3}>
                {successful ?
                    <Grid className="successful-text">
                      <CheckCircleTwoToneIcon fontSize="large"/>
                      <Typography variant="h5">Registration complete!</Typography>
                    </Grid>
                    :
                    <>
                      <Grid item className="auth-text-field">
                        <TextField
                            inputProps={{ autoComplete: 'off'}}
                            type="text"
                            label="First Name"
                            name="first_name"
                            value={formik.values?.first_name}
                            onChange={formik.handleChange}
                            fullWidth
                            error={emptyFields.first_name}
                            helperText={formik.errors.first_name}
                            onFocus={() => onFocusError('first_name')}
                        />
                      </Grid>
                      <Grid item className="auth-text-field">
                        <TextField
                            inputProps={{ autoComplete: 'off'}}
                            type="text"
                            label="Last Name"
                            name="last_name"
                            value={formik.values?.last_name}
                            onChange={formik.handleChange}
                            fullWidth
                            error={emptyFields.last_name}
                            helperText={formik.errors.last_name}
                            onFocus={() => onFocusError('last_name')}
                        />
                      </Grid>
                      <Grid item className="auth-text-field">
                        <TextField
                            inputProps={{
                              autoComplete: 'new-email',
                              form: {
                                autoComplete: 'off',
                              },}}
                            autoComplete='off'
                            name="email"
                            type="email"
                            label="Email"
                            value={formik.values?.email}
                            onChange={formik.handleChange}
                            fullWidth
                            error={emptyFields.email}
                            helperText={formik.errors.email}
                            onFocus={() => onFocusError('email')}
                        />
                      </Grid>
                      <Grid item className="auth-text-field">
                        <TextField
                            inputProps={{
                              autoComplete: 'new-password',
                              form: {
                                autoComplete: 'off',
                              },}}
                            autoComplete='off'
                            type="password"
                            name="password"
                            label="Password"
                            value={formik.values?.password}
                            onChange={formik.handleChange}
                            fullWidth
                            error={emptyFields.password}
                            helperText={formik.errors.password}
                            onFocus={() => onFocusError('password')}
                        />
                      </Grid>
                      <Grid item className="auth-text-field">
                        <TextField
                            inputProps={{ form: { autoComplete: 'off'}}}
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={formik.values?.confirmPassword}
                            onChange={formik.handleChange}
                            fullWidth
                            error={emptyFields.confirmPassword}
                            helperText={formik.errors.confirmPassword}
                            onFocus={() => onFocusError('confirmPassword')}
                        />
                      </Grid>
                      <Grid item className="auth-button">
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            loading={loading}
                        >
                          Sign Up
                        </LoadingButton>
                      </Grid>
                    </>
                }
                <Grid item className="auth-button redirect-button">
                  <Button
                      fullWidth
                      variant="outlined"
                      color="info"
                      onClick={() => setAuth("LOGIN")}
                  >
                    Login instead
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Container>
  );
};

export default Signup;

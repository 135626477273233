import React from "react";

const apiHandler = (commonMessage, config, fn, errorCallback) => {
    const user = JSON.parse(localStorage.getItem("user"));

    config = {
        ...config,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
    };

    if (user?.token) {
        config.headers.authorization = `Bearer ${user.token}`;
    }

    return fn(config).then(() => {

    }).catch(exception => {
        if (exception?.response?.status === 401) {
            localStorage.removeItem('user');
            window.location.href = '/signin';
        } else {
            errorCallback && errorCallback(exception?.message)
        }
    })
};

export default apiHandler;
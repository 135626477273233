import React, {useEffect, useRef, useState} from "react";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import {ReactReader} from "react-reader";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {darkReaderTheme, lightReaderTheme} from "./EpubReaderStyles";


const EpubReader = ({file, height, loading}) => {
    const [location, setLocation] = useState(0)
    const [page, setPage] = useState("")
    const [settingsLeft, setSettingsLeft] = useState(["darkMode"])
    const [settingsRight, setSettingsRight] = useState([])

    const rendition = useRef(null)
    const toc = useRef(null)

    const changeSettingsLeft = (event, newFormats) => {
        setSettingsLeft(newFormats);
    };

    const changeSettingsRight = (event, newFormats) => {
        setSettingsRight(newFormats);
    };

    useEffect(() => {
        rendition.current?.themes.fontSize(settingsRight.includes("largeText") ? '140%' : '100%')
    }, [settingsRight])

    useEffect(() => {
        if (settingsLeft.includes("darkMode")) {
            rendition.current?.themes?.override('color', '#fff')
            rendition.current?.themes?.override('background', '#000')
        } else {
            rendition.current?.themes?.override('color', '#000')
            rendition.current?.themes?.override('background', '#fff')
        }
    }, [settingsLeft, rendition.current])

    return <Box id="epub-reader">
        <Grid className="reader" height={height}>
            {loading ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    <CircularProgress className="loading-cover"/>
                </div>
                :
                file ?
                    <ReactReader
                        url={file}
                        location={location}
                        tocChanged={(_toc) => (toc.current = _toc)}
                        getRendition={(_rendition) => {
                            rendition.current = _rendition
                        }}
                        locationChanged={(loc) => {
                            setLocation(loc)
                            if (rendition.current && toc.current) {
                                const {displayed, href} = rendition.current.location.start
                                const chapter = toc.current.find((item) => item.href === href)
                                rendition.current?.themes.fontSize(settingsRight.includes("largeText") ? '140%' : '100%')
                                setPage(`Page ${displayed.page} of ${displayed.total} in chapter ${chapter ? chapter.label : 'N/A'}`)
                            }
                        }}
                        readerStyles={settingsLeft.includes("darkMode") ? darkReaderTheme : lightReaderTheme}
                        title={file?.name}
                        // epubOptions={{
                        //     flow: 'scrolled',
                        //     manager: 'continuous',
                        //     paginated: "scrolled",
                        // }}
                        loadingView={
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                            }}>
                                <CircularProgress className="loading-cover"/>
                            </div>
                        }
                    />
                    :
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                        <span>Error loading file</span>
                    </div>
            }
        </Grid>
        <Grid className="footer">
            <ToggleButtonGroup
                value={settingsLeft}
                disabled={!file}
                onChange={changeSettingsLeft}
                aria-label="text formatting"
            >
                <ToggleButton size="small" value="darkMode" color="success">Dark Mode</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="h6" textAlign="center">{page}</Typography>
            <ToggleButtonGroup
                value={settingsRight}
                disabled={!file}
                onChange={changeSettingsRight}
                aria-label="text formatting"
            >
                <ToggleButton size="small" value="largeText" color="success">Large Text</ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    </Box>
}

export default EpubReader;
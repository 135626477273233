import React, {useState} from "react";
import {
    Alert,
    AlertTitle,
    CircularProgress,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {ArrowCircleDown, ArrowCircleUp, Search, SearchOff} from "@mui/icons-material";
import {Autocomplete} from "@mui/lab";

const SearchBar = ({loadingInput, searchTerm, setSearchTerm, total, filters, setNewFilters, fileTypes, searchResults, noResultText, error, setError, authors, subjects, searchOnSelectClose}) => {
    const [showFilters, setShowFilters] = useState(false)

    return <Grid container className="search-bar">
        <Grid container className="search-bar-wrapper">
            <Grid container spacing={2} className="search-bar-row">
                <Grid item xs={12} sm={8}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Search</InputLabel>
                        <OutlinedInput
                            label="Search"
                            endAdornment={loadingInput && <CircularProgress size={22}/>}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            disabled={loadingInput}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Extension</InputLabel>
                        <Select
                            label="Extension"
                            value={filters.extension}
                            onChange={(e) => setNewFilters({...filters, extension: e.target.value})}
                            fullWidth
                            style={{ minWidth: '120px' }}
                            disabled={loadingInput}
                        >
                            {fileTypes.map(t => {return <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>})}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={showFilters ? "search-bar-row" : "search-bar-row-hidden"}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        label="Author"
                        onClose={searchOnSelectClose}
                        disableCloseOnSelect
                        multiple
                        value={filters.authors}
                        onChange={(e, newValue) => setNewFilters({...filters, authors: newValue})}
                        style={{minWidth: '120px'}}
                        disabled={loadingInput}
                        options={authors}
                        renderInput={(params) => <TextField {...params} label="Author" />}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        label="Subject"
                        onClose={searchOnSelectClose}
                        disableCloseOnSelect
                        multiple
                        value={filters.subjects}
                        onChange={(e, newValue) => setNewFilters({...filters, subjects: newValue})}
                        style={{minWidth: '120px'}}
                        disabled={loadingInput}
                        options={subjects}
                        renderInput={(params) => <TextField {...params} label="Subject" />}
                    />


                    {/*onRemove from selected in autocomplete*/}
                </Grid>
            </Grid>
            <FormGroup className="total-count-wrapper">
                <Typography variant="h6">{searchResults.length} / {total}</Typography>
                {showFilters ?
                    <ArrowCircleUp onClick={() => setShowFilters(!showFilters)}/>
                    :
                    <ArrowCircleDown onClick={() => setShowFilters(!showFilters)}/>
                }
            </FormGroup>
        </Grid>
        {searchResults.length === 0 && searchTerm.length === 0 && filters.authors.length === 0 && filters.subjects.length === 0 &&
            <Typography variant="h5" className="search-additional-text"><Search className="search-additional-icon" />Type something to search</Typography>
        }
        {noResultText && (searchTerm.length > 0 || filters.authors.length > 0 || filters.subjects.length > 0) &&
            <Typography variant="h5" className="search-additional-text"><SearchOff className="search-additional-icon" />You're too exacting, I found nothing...</Typography>
        }
        <Grid item xs={12} className="error-message">
            {error &&
                <Alert severity="error" onClose={() => setError("")}>
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            }
        </Grid>
    </Grid>
}

export default SearchBar;
import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import Reader from "../../../components/Defaults/Reader";

const ReaderDialog = ({onClose, id}) => {
    return <Dialog
        open={true}
        onClose={onClose}
        maxWidth='xl'
        fullWidth={true}
    >
        <DialogContent>
            <Box height={600}>
                <Reader id={id} height={600}/>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default ReaderDialog;
import React from 'react';
import "./DocumentGrid.scss";
import {Container, Grid} from "@mui/material";
import ElementGrid from "./ElementGrid";
import {LoadingButton} from "@mui/lab";
import {useSelector} from "react-redux";
import SimpleElementGrid from "./SimpleElementGrid";

const DocumentGrid = ({documents, downloadDocument, getCover, getDocument, updatePage, loadingPage, total}) => {
    const {simple_ui} = useSelector(state => state.user.data);

    return (
        <Container id="document-grid">
            <Grid container spacing={4} className="search-result">
                {documents?.map((document, i) => {
                    return simple_ui ?
                        <SimpleElementGrid
                            key={i}
                            document={document}
                            downloadDocument={downloadDocument}
                            getDocument={getDocument}
                        />
                        :
                        <ElementGrid
                            key={i}
                            document={document}
                            downloadDocument={downloadDocument}
                            getCover={getCover}
                            getDocument={getDocument}
                        />
                })}
            </Grid>

            <Grid item xs={12}>
                {documents?.length > 0 && total !== documents.length &&
                    <LoadingButton
                        size="small"
                        loading={loadingPage}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="load-more-button"
                        onClick={updatePage}
                    >
                        Load more
                    </LoadingButton>}
            </Grid>
        </Container>
    );
};

export default DocumentGrid;
